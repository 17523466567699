<template>
  <sdModal
    centered
    type="primary"
    :title="i18n.t(`${modalType}.modal.title`)"
    :visible="data.visible"
    :onCancel="closeModal"
    :width="500"
    class="publish-modal"
  >
    <div class="modal__text">
      <template v-if="data.data">
        <a-form :model="publishData" :rules="rules" ref="createTrading" name="createTrading">
          <template v-if="errors">
            <a-alert
              :message="
                i18n.t(`accounts.public.error_${error[0]}`, {
                  error: typeof error[1] === 'object' ? error[1][0] : error[1],
                })
              "
              type="error"
              show-icon
              class="mb-5"
              v-for="error of Object.entries(errors)"
              :key="error[0]"
            />
          </template>
          <a-form-item ref="title" name="title" :label="i18n.t('publish.modal.input_title')">
            <a-input
              :maxlength="100"
              show-count
              :disabled="modalType === 'unpublish'"
              v-model:value="publishData.title"
            />
            <p class="publish-modal__text-description" v-if="modalType === 'publish'">
              *{{ i18n.t('publish.modal.title_description') }}
            </p>
          </a-form-item>
          <a-form-item ref="description" name="description" :label="i18n.t('publish.modal.input_description')">
            <a-textarea
              :rows="4"
              :placeholder="i18n.t('publish.modal.textarea')"
              :maxlength="255"
              show-count
              :disabled="modalType === 'unpublish'"
              v-model:value="publishData.description"
            />
            <p class="publish-modal__text-description">*{{ i18n.t('publish.modal.info_description') }}</p>
          </a-form-item>
          <a-form-item ref="system_type" name="system_type" :label="i18n.t('publish.modal.system_type')">
            <a-select
              ref="select"
              v-model:value="publishData.system_type"
              :disabled="modalType === 'unpublish'"
              style="width: 100%"
              required
            >
              <a-select-option :value="index" v-for="(type, index) of systemTypes" :key="index">
                {{ type }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-form>
      </template>
      <p class="publish-modal__show-terms" @click="showTerms = !showTerms">
        <sdFeatherIcons type="info" />{{ i18n.t('publish.modal.terms') }}
      </p>
      <p v-if="showTerms" class="publish-modal__terms" v-html="i18n.t(`${modalType}.modal.description`)" />
    </div>
    <div class="create-account__buttons">
      <sdButton size="large" :disabled="isLoading" type="danger" raised style="width: 49%" @click="closeModal">
        {{ i18n.t('publish.modal.cancel') }}
      </sdButton>
      <sdButton
        size="large"
        :disabled="isLoading || errorString"
        type="success"
        @click="changePublish"
        raised
        style="width: 49%"
      >
        {{ i18n.t(`${modalType}.modal.button`) }}
      </sdButton>
    </div>
  </sdModal>
</template>

<script>
import { computed, reactive, ref, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

export default {
  props: {
    data: {
      type: Object,
      default() {
        return {
          visible: false,
          data: null,
        };
      },
    },
  },

  setup(props, { emit }) {
    const i18n = reactive(useI18n());
    const { state, dispatch } = useStore();

    const publishData = ref({ title: '', description: '', system_type: 0 });
    const showTerms = ref(false);
    const errorString = ref(false);

    const isLoading = computed(() => state.publicAccounts.loading);
    const modalType = computed(() =>
      props.data.data?.mt5_account_data?.modificator === 'trade' ? 'publish' : 'unpublish',
    );

    const systemTypes = ref([
      i18n.t('publish.modal.manual'),
      i18n.t('publish.modal.mechanical'),
      i18n.t('publish.modal.automatic'),
    ]);

    const errors = computed(() => state.publicAccounts.error);

    const stringValidation = (data) => {
      const validate = /[a-zA-Z1-9 -().^+]/g;
      if (publishData.value[data.field].replace(validate, '').length) {
        errorString.value = true;
        return Promise.reject(i18n.t('messages.error_string'));
      } else {
        errorString.value = false;
        return Promise.resolve();
      }
    };

    const rules = reactive({
      title: [
        {
          trigger: ['blur'],
          type: 'string',
          validator: stringValidation,
        },
      ],
      description: [
        {
          trigger: ['blur'],
          type: 'string',
          validator: stringValidation,
        },
      ],
    });

    const closeModal = () => {
      dispatch('clearPublicErrors');
      emit('update:data', { visible: false, data: props.data.data, type: props.data.type });
    };

    const changePublish = async () => {
      await dispatch(`${modalType.value}Account`, { id: props.data.data.id, data: publishData.value });
      if (!errors.value) {
        closeModal();
      }
    };

    watchEffect(() => {
      publishData.value.title = props.data.data?.mt5_account_data?.name ?? props.data.data?.name;
    });

    return {
      i18n,
      modalType,
      publishData,
      showTerms,
      isLoading,
      errors,
      systemTypes,
      rules,
      errorString,
      closeModal,
      changePublish,
    };
  },
};
</script>

<style lang="scss">
.ant-input-textarea-show-count {
  position: relative;

  &:after {
    content: attr(data-count);
    position: absolute;
    bottom: 12px;
    right: 1px;
    background: #fff;
    padding: 0 10px;
  }
}
.ant-form-item {
  flex-wrap: wrap;
}

.ant-form-item-control {
  min-width: 100%;
}

.publish-modal {
  &__text-description {
    font-size: 12px;
    margin-left: 5px;
    margin-top: 5px;
    opacity: 0.8;
  }

  &__show-terms {
    color: #2c9aea;
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    margin-left: 5px;
  }

  &__terms {
    margin-top: 10px;
  }

  .ant-form-item {
    margin-bottom: 10px !important;
  }
  p {
    margin-bottom: 0;
  }
}
</style>
